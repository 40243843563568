//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  watch: {
    $route(to, from) {
      this.$store.dispatch('hideMenu')
    },
  },
  computed: {
    menu() {
      return this.$store.state.menu
    },
  },
}
