import { projectsQuery } from '~/graphql/queries/projects'

export const state = () => ({
  showMenu: false,
  projects: [],
  menu: null
})

export const mutations = {
  toggleMenu(state) {
    state.showMenu = !state.showMenu
  },
  hideMenu(state) {
    state.showMenu = false
  },
  setProjects(state, data) {
    state.projects = data
  },
  setMenu(state, data) {
    state.menu = data
  },
}

export const actions = {
  reset ({commit}) {
    commit('resetColors')
    commit('hideMenu')
  },
  toggleMenu ({commit}) {
    commit('toggleMenu')
  },
  hideMenu ({commit}) {
    commit('hideMenu')
  },
  async nuxtServerInit({ commit }, { app, payload, route}) {

    if (payload && payload.data) {
      commit('setProjects', payload.data.projects.data)
    } else if (process.env.NODE_ENV === 'development' || route.query.preview) {
      await app.apolloProvider.defaultClient.query({ query: projectsQuery, amountLimit: 2500}).then(response => {
        commit('setProjects', response.data.projects.data)
      });
    }


    if (process.server) {
      const menu = await this.$axios.$get(app.$strapi.options.url + `/menus/1?nested=true&populate=deep,3`)
      commit('setMenu', menu)
    }
  }
  
}